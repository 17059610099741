import React, {useEffect, useMemo, useState} from "react";
import {Table, Button, Flex, Tooltip, Modal, Space, Empty, Badge} from "antd";
import {
    EditOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    FilePdfOutlined,
    EuroOutlined
} from "@ant-design/icons";

import '../IraTable/IraTable.css'
import {axiosInstance} from "../../../api/axiosInstance"
import {toast} from "react-toastify";
import dayjs from 'dayjs';
import customParseFormat from "dayjs/plugin/customParseFormat";
import {API_URL} from "../../../constants/constants";
import iraItemForm from "../IraItemForm/IraItemForm";

dayjs.extend(customParseFormat);


const IraTable = ({
                      userData,
                      tableData,
                      pageNumber,
                      pageSize,
                      totalRows,
                      onFilterChanged,
                      setMode,
                      setRowData,
                      setIraModalOpened,
                      refreshData,
                      setIraPaymentModalOpened,
                      fetchPayments,
                      fetchIraItems,
                      fetchProjects,
                      //fetchCompaniesByOwnerId
                  }) => {
    const [arrow, setArrow] = useState("Show");
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const [modal, contextHolder] = Modal.useModal();
    const isUserRole = userData?.authorities?.includes('ROLE_ZAPOSLENIK');
    const isAdminRole = userData?.authorities?.includes('ROLE_ADMINISTRATOR');

    useEffect(() => {
        setExpandedRowKeys([])
    }, [tableData])

    const confirm = (id) => {
        modal.confirm({
            title: "Potvrda",
            icon: <ExclamationCircleOutlined/>,
            content: "Jeste li sigurni da želite stornirati račun?",
            okText: "Da",
            cancelText: "Ne",
            centered: true,
            onOk: () => {
                axiosInstance.delete(`/api/v1/ira/${id}`)
                    .then(res => {
                        toast.success("Račun uspješno storniran!");
                        refreshData();
                    }).catch(ex => {
                    toast.error(`${ex.response.data.errorMessage}`);
                })
            },
        });
    };

    const mergedArrow = useMemo(() => {
        if (arrow === "Hide") {
            return false;
        }

        if (arrow === "Show") {
            return true;
        }

        return {
            pointAtCenter: true,
        };
    }, [arrow]);

    const tableColumns = () => {
        return [
            /*            {
                            title: "",
                            render: (text, record) => (
                                <div style={{display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                                    <Badge
                                        color={record.paymentPercentage === 100.00 ? "green" : "red"}
                                        diameter={24}
                                        text={!record.active ? 'stornirano' : ''}
                                    ></Badge>
                                </div>
                            )
                        },*/
            {
                title: "Broj računa",
                dataIndex: ["invoiceNumber"],
                key: "invoiceNumber"
            },
            {
                title: "Investitor",
                dataIndex: ["investor", "name"],
                key: "investor",
            },
            {
                title: "Datum kreiranja",
                dataIndex: ["creationDate"],
                key: "creationDate",
                render: text => (<>{dayjs(text, 'YYYY-MM-DD').format("DD.MM.YYYY.")}</>),
                sortDirections: ['ascend', 'descend'],
                sorter: true,
            },
            {
                title: "Nositelj",
                dataIndex: ["owner", "nameStr"],
                key: "owner",
            },
            {
                title: "Tvrtka",
                dataIndex: ["company", "name"],
                key: "company",
            },
            {
                title: "Iznos bez PDV-a",
                dataIndex: "priceWithoutPdv",
                key: "priceWithoutPdv",
                render: (text, record, index) => {
                    return <>{twoPointDecimal(text) + " €"}</>
                },
                sortDirections: ['ascend', 'descend'],
                sorter: true,
            },
            {
                title: "Iznos PDV-a",
                dataIndex: "pdvPrice",
                key: "pdvPrice",
                render: (text, record, index) => {
                    return <>{twoPointDecimal(text) + " €"}</>
                },
                sortDirections: ['ascend', 'descend'],
                sorter: true,
            },
            {
                title: "Ukupno",
                dataIndex: "priceWithPdv",
                key: "priceWithPdv",
                render: (text, record, index) => {
                    return <>{twoPointDecimal(text) + " €"}</>
                },
                sortDirections: ['ascend', 'descend'],
                sorter: true,

            },
            {
                title: "Postotak naplate",
                dataIndex: "paymentPercentage",
                key: "paymentPercentage",
                render: (text, record, index) => {
                    return <>{twoPointDecimal(text) + " %"}</>
                },
                sortDirections: ['ascend', 'descend'],
                //defaultSortOrder: 'ascend',
                sorter: true,
            },
            {
                title: "",
                key: "operation",
                width: "200px",
                className: 'do-not-edit',
                render: (text, record, index) => (
                    <Flex vertical={false} gap={"4px"} style={{justifyContent: 'center'}}>
                        <>
                            <Tooltip
                                placement="top"
                                title={<span>Preuzmi PDF</span>}
                                arrow={mergedArrow}
                            >
                                <Button
                                    type="text"
                                    size="large"
                                    icon={<FilePdfOutlined/>}
                                    onClick={async () => {
                                        axiosInstance.get(`${API_URL}/api/v1/ira/generate-invoice-pdf/${record.id}`, {responseType: 'blob'})
                                            .then(response => {

                                                console.log(response.headers, 'response.headers')

                                                const disposition = response.headers['content-disposition'];
                                                let filename = 'racun.pdf'; // default value in case filename is not found
                                                if (disposition && disposition.includes('attachment')) {
                                                    const filenameRegex = /filename[^;=\n]*=(['"]?)([^'"\n]*)\1/;
                                                    const matches = filenameRegex.exec(disposition);
                                                    if (matches != null && matches[2]) {
                                                        filename = matches[2];
                                                    }
                                                }

                                                const href = URL.createObjectURL(response.data);
                                                const link = document.createElement('a');
                                                link.href = href;
                                                link.setAttribute('download', filename);
                                                document.body.appendChild(link);
                                                link.click();
                                                document.body.removeChild(link);
                                                URL.revokeObjectURL(href);
                                            }).catch(ex => {
                                            toast.error("Dogodila se greška prilikom generiranja računa.")
                                        });
                                    }}
                                />
                            </Tooltip>
                            <Tooltip
                                placement="top"
                                title={<span>Uređivanje</span>}
                                arrow={mergedArrow}
                            >
                                <Button
                                    type="text"
                                    size="large"
                                    icon={<EditOutlined/>}
                                    onClick={async () => {
                                        await fetchIraItems(record.id)
                                        setMode("edit");
                                        setRowData({
                                            ...record,
                                            creationDate: dayjs(record?.creationDate),
                                            creationTime: dayjs(record?.creationTime, "HH:mm:ss"),
                                            delivery: dayjs(record?.delivery),
                                            project: {
                                                ...record.project,
                                                id: record.project?.id ? record.project.id : ""
                                            },
                                            investor: {
                                                ...record.investor,
                                                id: record.investor?.id ? record.investor.id : ""
                                            },
                                            owner: {
                                                ...record.owner,
                                                id: record.owner?.id ? record.owner.id : ""
                                            },
                                            company: {
                                                ...record.company,
                                                id: record.company?.id ? record.company.id : ""
                                            },
                                        });
                                        fetchProjects(record.investor?.id)
                                        //fetchCompaniesByOwnerId(record.owner.id);
                                        setIraModalOpened(true);
                                    }}
                                    disabled={!record.active || record.statusStr === "Zatvoren"}
                                />
                            </Tooltip>
                            <Tooltip
                                placement="top"
                                title={<span>Uplate</span>}
                                arrow={mergedArrow}
                            >
                                <Button
                                    type="text"
                                    size="large"
                                    icon={<EuroOutlined/>}
                                    onClick={async () => {
                                        setRowData(record);
                                        fetchPayments(record.id)
                                        setIraPaymentModalOpened(true);
                                    }}
                                    disabled={!record.active}
                                />
                            </Tooltip>
                            <Tooltip
                                placement="top"
                                title={<span>Storniranje</span>}
                                arrow={mergedArrow}
                            >
                                <Space>
                                    <Button
                                        type="text"
                                        size="large"
                                        danger
                                        icon={<DeleteOutlined/>}
                                        onClick={() => confirm(record.id)}
                                        disabled={!record.active || record.statusStr === "Zatvoren"}
                                    />
                                </Space>
                            </Tooltip>
                        </>
                    </Flex>
                ),
            },
        ].filter(item => !item.hidden);
    };
    const twoPointDecimal = (number) => Number(number).toFixed(2);

    /*const uraItemsTable = () => {
        return <Table
            size={"small"}
            bordered={true}
            style={{marginTop: '16px', marginBottom: '32px', marginRight: '32px'}}
            title={data => {
                return <p style={{marginLeft: '16px', marginTop: '4px', marginBottom: '4px'}}><b>Stavke računa</b></p>
            }}
            rowKey={"id"}
            columns={[
                {
                    title: "Rbr",
                    dataIndex: "id",
                    key: "id",
                    render: (id, record, index) => {
                        ++index;
                        return index;
                    },
                    align: "left"
                },
                {
                    title: "Naziv",
                    dataIndex: "name",
                    key: "name",
                    render: (value, record, index) => {
                        return (<p>{value ? value : "-"}</p>);
                    },
                    align: "left"
                },
                {
                    title: "JM",
                    dataIndex: "jm",
                    key: "jm",
                    render: (value, record, index) => {
                        return (<p>{value ? value : "-"}</p>);
                    },
                    align: "left"
                },
                {
                    title: "Kol",
                    dataIndex: "amount",
                    key: "amount",
                    render: value => {
                        return `${value}`;
                    },
                    align: "left"
                },
                {
                    title: "Cijena",
                    dataIndex: "priceWithoutPdv",
                    key: "priceWithoutPdv",
                    render: value => {
                        return `${twoPointDecimal(value)} €`;
                    },
                    align: "left"
                },
                {
                    title: "Popust",
                    dataIndex: "rebate",
                    key: "rebate",
                    render: value => {
                        return `${twoPointDecimal(value)} €`;
                    },
                    align: "left"
                },
                {
                    title: "Iznos",
                    dataIndex: "total",
                    key: "total",
                    render: value => {
                        return `${twoPointDecimal(value)} €`;
                    },
                    align: "left"
                }]
            }
            dataSource={iraItemFormInitialValues?.items}
            pagination={false}
        ></Table>;
    }*/
    const rowClassName = (record, index) => {
        if (record.active) {
            if (record.paymentPercentage === 0) {
                return 'unpaid';
            } else if (record.paymentPercentage === 100) {
                return record.paymentPercentage >= 100 ? 'paid' : 'unpaid';
            } else if (record.paymentPercentage > 0 && record.paymentPercentage < 100) {
                return 'partly-paid'
            }
        } else {
            return 'storno';
        }
    };

    return (
        <>
            <style>{`
                .dot {
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 5px;
                }
                .dot-active {
                    background-color: #FF9991;
                }
                .dot-inactive {
                    background-color: #A6F7C2;
                }
                .dot-storno {
                    background-color: #CFD1D0;
                }
                .dot-partly-paid {
                    background-color: #F7ED92;
                }
            `}</style>
            <div style={{marginLeft: '8px'}}>
                <h4>Agenda</h4>
                <ul style={{listStyleType: "none", padding: "0px"}}>
                    <li><span className="dot dot-active"></span>Otvoreni računi</li>
                    <li><span className="dot dot-inactive"></span>Zatvoreni računi</li>
                    <li><span className="dot dot-partly-paid"></span>Djelomično naplaćen račun</li>
                    <li><span className="dot dot-storno"></span>Stornirani računi</li>
                </ul>
            </div>
            <Table
                columns={tableColumns()}
                dataSource={tableData}
                onChange={(pagination, filters, sorter) =>
                    onFilterChanged(pagination, filters, sorter)
                }
                /*expandable={{
                    showExpandColumn: false,
                    expandRowByClick: false,
                    expandedRowKeys: expandedRowKeys,
                    expandedRowRender: (record) => uraItemsTable(),
                    onExpand: (expanded, record) => {
                        const keys = [];
                        if (expanded) {
                            fetchIraItems(record.id).then(res => {
                                keys.push(record.id);
                                setExpandedRowKeys(keys);
                            })
                        } else {
                            setExpandedRowKeys(keys);
                        }
                    }
                }}*/
                style={{marginTop: "32px"}}
                pageNumber={pageNumber}
                pageSize={pageSize}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30'],
                    total: totalRows,
                    showTotal: (total, range) => `Ukupan broj rezultata: ${totalRows}`
                }}
                bordered
                scroll={{x: 700}}
                locale={{emptyText: <Empty description="Nije pronađen niti jedan račun."/>}}
                rowClassName={rowClassName}
                components={{
                    body: {
                        row: ({className, ...props}) => {
                            let tClassName = className.split(" ")[2]
                            let currentStyle;
                            switch (tClassName) {
                                case 'paid':
                                    currentStyle = {backgroundColor: '#A6F7C2', color: 'black'};
                                    break;
                                case 'unpaid':
                                    currentStyle = {backgroundColor: '#FF9991', color: 'black'};
                                    break;
                                case 'partly-paid':
                                    currentStyle = {backgroundColor: '#F7ED92', color: 'black'};
                                    break;
                                case 'storno':
                                    currentStyle = {backgroundColor: '#CFD1D0', color: 'black'};
                                    break;
                                default:
                                    break;
                            }

                            return (
                                <tr
                                    {...props}
                                    style={
                                        currentStyle
                                    }
                                />
                            )
                        }
                    },
                }}
                summary={() => {
                    {
                        return tableData && tableData.length > 0 ?
                            <Table.Summary>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={5}>
                                        <b>Ukupno izdani računi</b>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={5} colSpan={1}>
                                        {
                                            twoPointDecimal(tableData[0]?.totalSumWithoutVAT) + " €"
                                        }
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={6} colSpan={1}>
                                        {
                                            twoPointDecimal(tableData[0]?.totalSumVAT) + " €"
                                        }
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={7} colSpan={1}>
                                        {
                                            twoPointDecimal(tableData[0]?.totalSumWithVAT) + " €"
                                        }
                                    </Table.Summary.Cell>

                                </Table.Summary.Row>
                            </Table.Summary> : <></>
                    }

                }}
            />
            {contextHolder}
        </>
    );
};
export default IraTable;
