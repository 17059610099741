import React, {useEffect, useMemo, useState} from "react";
import {Table, Button, Flex, Tooltip, Modal, Space, Empty} from "antd";
import {
    EditOutlined,
    SearchOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    SignatureOutlined
} from "@ant-design/icons";


import '../ProjectsTable/ProjectsTable.css'
import {axiosInstance} from "../../../api/axiosInstance"
import {toast} from "react-toastify";
import dayjs from 'dayjs';
import customParseFormat from "dayjs/plugin/customParseFormat";
import {useNavigate} from "react-router-dom";

dayjs.extend(customParseFormat);


const ProjectsTable = ({
                           userData,
                           tableData,
                           pageNumber,
                           pageSize,
                           totalRows,
                           onFilterChanged,
                           setMode,
                           setProjectModalOpened,
                           setRowData,
                           refreshData,
                           fetchUsersByProjectId,
                           fetchInvestorByProjectId,
                           //fetchCompaniesByOwnerId,
                           setSelectedProjectId,
                           setSelectedSubProjectId,
                           fetchUsersOnMap,
                           fetchUras,
                           fetchIras,
                           fetchRecapitulationData,
                           fetchSalaries,
                           setFileList,
                           readOnly
                       }) => {
    const [arrow, setArrow] = useState("Show");

    const [modal, contextHolder] = Modal.useModal();

    let navigate = useNavigate();

    const isUserRole = userData?.authorities?.includes('ROLE_ZAPOSLENIK');
    const isAdminRole = userData?.authorities?.includes('ROLE_ADMINISTRATOR');

    const fetchFileList = async (projectId) => {
        try {
            const res = await axiosInstance.get(`/api/v1/files/projects/${projectId}`);
            setFileList(res.data);
        } catch (ex) {
            console.log('Error while calling fetchFileList().')
        }
    }

    const confirm = (id) => {
        modal.confirm({
            title: "Potvrda",
            icon: <ExclamationCircleOutlined/>,
            content: "Jeste li sigurni da želite obrisati posao?",
            okText: "Da",
            cancelText: "Ne",
            centered: true,
            onOk: () => {
                axiosInstance.delete(`/api/v1/projects/${id}`)
                    .then(res => {
                        toast.success("Posao uspješno obrisan!");
                        refreshData();
                    }).catch(ex => {
                    toast.error(`${ex.response.data.errorMessage}`);
                })
            },
        });
    };

    const mergedArrow = useMemo(() => {
        if (arrow === "Hide") {
            return false;
        }

        if (arrow === "Show") {
            return true;
        }

        return {
            pointAtCenter: true,
        };
    }, [arrow]);

    const dateSorter = (a, b) => {
        const dateA = dayjs(a); // Replace 'dateColumn' with the actual key of the date column
        const dateB = dayjs(b); // Replace 'dateColumn' with the actual key of the date column

        if (dateA.isBefore(dateB)) return -1;
        if (dateA.isAfter(dateB)) return 1;
        return 0;
    };



    const tableColumns = () => {
        return [
            {
                title: "Oznaka posla",
                dataIndex: "name",
                key: "name",
                render: (value) => <div>{value ? value : "-"}</div>
            },
            {
                title: "Vrsta posla",
                dataIndex: "projectTypeStr",
                key: "projectTypeStr",
                hidden: !readOnly
            },
            {
                title: "Investitor",
                dataIndex: ["investor", "name"],
                key: "investor",
            },
            {
                title: "Nositelj",
                dataIndex: ["owner", "nameStr"],
                key: "owner",
            },
            {
                title: "Tvrtka",
                dataIndex: ["company", "name"],
                key: "company",
            },
            {
                title: "Lokacija",
                dataIndex: ["location"],
                key: "location",
                render: ((value) => value.cadastralParcel + ' - ' + value.cadastralMunicipality)
            },
            {
                title: "Predloženi rok",
                dataIndex: "proposedDeadline",
                key: "proposedDeadline",
                //sortDirections: ['descend', 'ascend', 'descend'],
                //defaultSortOrder: "descend",
                sortDirections: ['ascend', 'descend'],
                sorter: true,
                render: ((date) => dayjs(date).format("DD.MM.YYYY."))
            },
            {
                title: "Cijena",
                dataIndex: "price",
                key: "price",
                sortDirections: ['ascend', 'descend'],
                sorter: true,
                render: ((value) => `${Number(value).toFixed(2)} €`),
                hidden: !isAdminRole
            },
            {
                title: "Postotak naplate",
                dataIndex: "paymentPercentage",
                key: "paymentPercentage",
                sortDirections: ['ascend', 'descend'],
                sorter: true,
                hidden: !isAdminRole,
                render: ((value) => `${value !== "null" ? Number(value).toFixed(2) : '0.00'} %`),
            },
            /*       {
                    title: "Napomena",
                    dataIndex: "remark",
                    key: "remark",
                  }, */
            {
                title: "Status",
                dataIndex: ["status", "name"],
                key: "status",
            },
            {
                title: "",
                key: "operation",
                //fixed: "right",
                width: "200px",
                render: (text, record, index) => (
                    <Flex vertical={false} gap={"4px"} style={{justifyContent: 'center'}}>
                        <Tooltip
                            placement="top"
                            title={<span>Pregled</span>}
                            arrow={mergedArrow}
                        >
                            <Button
                                type="text"
                                size="large"
                                icon={<SearchOutlined/>}
                                onClick={() => {
                                    setMode("preview");
                                    fetchUsersByProjectId(record.id);
                                    fetchUsersOnMap(record.id, record.projectSubTypeId);
                                    fetchUras(record.id);
                                    fetchIras(record.id);
                                    fetchRecapitulationData(record.id);
                                    fetchSalaries(record.id);
                                    fetchInvestorByProjectId(record.id);
                                    //fetchCompaniesByOwnerId(record.owner.id);
                                    setRowData({...record, proposedDeadline: dayjs(record?.proposedDeadline)});
                                    setSelectedSubProjectId(record.projectSubTypeId);
                                    fetchFileList(record.id);
                                    setProjectModalOpened(true);
                                }}
                            />
                        </Tooltip>
                        {!readOnly ?
                            <>
                                <Tooltip
                                    placement="top"
                                    title={<span>Uređivanje</span>}
                                    arrow={mergedArrow}
                                >
                                    <Button
                                        type="text"
                                        size="large"
                                        icon={<EditOutlined/>}
                                        onClick={() => {
                                            setMode("edit");
                                            setSelectedProjectId(record.id);
                                            setSelectedSubProjectId(record.projectSubTypeId);
                                            fetchUsersByProjectId(record.id);
                                            fetchUsersOnMap(record.id, record.projectSubTypeId);
                                            fetchUras(record.id);
                                            fetchIras(record.id);
                                            fetchRecapitulationData(record.id);
                                            fetchSalaries(record.id);
                                            fetchInvestorByProjectId(record.id);
                                            //fetchCompaniesByOwnerId(record.owner.id);
                                            setRowData({...record, proposedDeadline: dayjs(record?.proposedDeadline)});
                                            fetchFileList(record.id);
                                            setProjectModalOpened(true);
                                        }}
                                        disabled={userData?.authorities?.includes('ROLE_ZAPOSLENIK')}
                                    />
                                </Tooltip>
                                <Tooltip
                                    placement="top"
                                    title={<span>Povijest poruka</span>}
                                    arrow={mergedArrow}
                                >
                                    <Space>
                                        <Button
                                            type="text"
                                            size="large"
                                            icon={<SignatureOutlined/>}
                                            onClick={() => navigate("/noticeboard", {state: {projectId: record.id}})}
                                        />
                                    </Space>
                                </Tooltip>
                                <Tooltip
                                    placement="top"
                                    title={<span>Brisanje</span>}
                                    arrow={mergedArrow}
                                >
                                    <Space>
                                        <Button
                                            type="text"
                                            size="large"
                                            danger
                                            icon={<DeleteOutlined/>}
                                            onClick={() => confirm(record.id)}
                                            disabled={userData?.authorities?.includes('ROLE_ZAPOSLENIK')}
                                        />
                                    </Space>
                                </Tooltip>
                            </>
                            : <></>}
                    </Flex>
                ),
            },
        ].filter(item => !item.hidden);
    };

    return (
        <>
            <Table
                columns={tableColumns()}
                dataSource={tableData}
                onChange={(pagination, filters, sorter) =>
                    onFilterChanged(pagination, filters, sorter)
                }
                style={{marginTop: "32px"}}
                pageNumber={pageNumber}
                pageSize={pageSize}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30'],
                    total: totalRows,
                    showTotal: (total, range) => `Ukupan broj rezultata: ${totalRows}`
                }}
                bordered
                scroll={{x: 700}}
                locale={{emptyText: <Empty description="Nije pronađen niti jedan posao."/>}}
            />
            {contextHolder}
        </>
    );
};
export default ProjectsTable;
