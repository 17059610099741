import React, {useEffect, useState} from "react";

import {axiosInstance} from "../../api/axiosInstance";

import {toast} from "react-toastify";

import {Button, Form, Flex, theme} from "antd";
import {PlusOutlined} from "@ant-design/icons";

import ModalComponent from "../../components/ModalComponent/ModalComponent";

import "./CompanyAdministrationPage.css";
import CompanyAdministrationForm
    from "../../components/Administration/Company/CompanyAdministrationForm/CompanyAdministrationForm";
import CompanyAdministrationTable
    from "../../components/Administration/Company/CompanyAdministrationTable/CompanyAdministrationTable";
import CompanyAdministrationFilter
    from "../../components/Administration/Company/CompanyAdministrationFilter/CompanyAdministrationFilter";
import {API_URL} from "../../constants/constants";

const CompanyAdministrationPage = (props) => {
    const [mode, setMode] = useState("insert");

    const [filterForm] = Form.useForm();
    const [companyDataForm] = Form.useForm();
    const [rowData, setRowData] = useState();

    const [tableData, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [fileList, setFileList] = useState([]);
    const [activeKey, setActiveKey] = useState(["1", "2"]);

    const [uploadedImage, setUploadedImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [previewOpen, setPreviewOpen] = useState(false);

    const [loading, setLoading] = useState(false);
    const [
        companyAdministrationModalOpened,
        setCompanyAdministrationModalOpened,
    ] = useState(false);

    const {token} = theme.useToken();

    const pageStyle = {
        maxWidth: "none",
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
        height: "100%",
    };

    const [owners, setOwners] = useState([]);
    const [initialValues, setInitialValues] = useState({
        name: "",
        oib: "",
        address: {street: "", houseNumber: "", postNumber: "", city: ""},
        owner: {id: ""},
        seatOfCourt: "",
        mbs: "",
        iban: "",
        bankName: "",
        bankAddress: "",
        boardMembers: "",
        shareCapital: "",
        shareCapitalCurrency: "kn",
        logo: {},
    });

    const fetchCompanies = async (values) => {
        try {
            const response = await axiosInstance.get(
                `/api/v1/companies/find-by?searchTerm=${values.searchTerm}&page=${
                    pageNumber - 1
                }&size=${pageSize}`
            );

            setTableData(
                response?.data?.content.map((element) => {
                    return {
                        ...element,
                        key: element.id,
                        addressStr: element.address.street
                            ? `${element.address.street} ${
                                element.address.houseNumber ? element.address.houseNumber + "," : ""
                            } ${
                                element.address.postNumber ? element.address.postNumber : ""
                            } ${element.address.city ? element.address.city : ""}`
                            : "",
                        ownerStr: `${element.owner.firstName} ${element.owner.lastName}`,
                    };
                })
            );
            setTotalRows(response?.data?.totalElements);
        } catch (ex) {
            console.log('Error happened while calling fetchCompanies()');
        }
    };

    const fetchOwners = async () => {
        try {
            const response = await axiosInstance.get(`/api/v1/users/find-all`);
            const defaultValue = [{value: "", label: "Odaberite vlasnika"}];
            const mappedResponse =
                response && response.data
                    ? defaultValue.concat(
                        response.data.filter(x => x.jobTitle === 'direktor').map((x) => {
                            return {
                                value: x.id,
                                label: `${x.firstName} ${x.lastName}`,
                            };
                        })
                    )
                    : defaultValue;
            setOwners(mappedResponse);
        } catch (ex) {
            console.log('Error happened while calling fetchOwners()');
        }
    };


    const fetchCompanyLogo = async (companyId) => {
        try {
            const response = await axiosInstance.get(`/api/v1/companies/get-company-logo/${companyId}`);
            if (response.data) {
                let dataCopy = [...fileList].concat(response.data);
                console.log(dataCopy, 'dataCopy');
                setFileList(dataCopy);
                const imagePreviewResponse = await axiosInstance.get(`${API_URL}/api/v1/companies/download-file/${companyId}`)
                if (imagePreviewResponse && imagePreviewResponse.data) {
                    setPreviewImage(imagePreviewResponse.data);
                }
            }
        } catch (ex) {
            console.log('Error happened while calling fetchOwners()');
        }
    };


    useEffect(() => {
        fetchCompanies({searchTerm: ""});
        fetchOwners();
    }, [pageNumber, pageSize]);

    const onFormFilterSubmit = async (values) => {
        fetchCompanies(values);
    };

    const onCompanyDataSubmit = (values) => {
        const newValues = {...values, logo: uploadedImage}
        if (mode === "insert") {
            axiosInstance
                .post("/api/v1/companies", newValues)
                .then((res) => {
                    setLoading(false);
                    setCompanyAdministrationModalOpened(false);
                    setPreviewImage(null);
                    setUploadedImage(null);
                    setFileList([]);
                    companyDataForm.resetFields();
                    toast.success("Tvrtka uspješno spremljena!");
                    fetchCompanies({searchTerm: ""});
                })
                .catch((ex) => {
                    toast.error("Došlo je do greške prilikom spremanja tvrtke!");
                });
        } else if (mode === "edit") {
            axiosInstance
                .patch("/api/v1/companies", newValues)
                .then((res) => {
                    setLoading(false);
                    setCompanyAdministrationModalOpened(false);
                    setPreviewImage(null);
                    setUploadedImage(null);
                    setFileList([]);
                    companyDataForm.resetFields();
                    toast.success("Tvrtka uspješno ažurirana!");
                    fetchCompanies({searchTerm: ""});
                })
                .catch((ex) => {
                    toast.error("Došlo je do greške prilikom ažuriranja tvrtke!");
                });
        }
    };

    const onFilterChanged = async (pagination, filters, sorter) => {
        setPageNumber(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const handleSubmitCompany = async () => {
        try {
            const validationResult = await companyDataForm.validateFields();
            if (!validationResult.errorFields) {
                companyDataForm.submit();
                setLoading(true);
            }
        } catch (ex) {
            console.log(ex);
        }
    };

    const companyDataFormTitle = () => {
        if (mode === "insert") {
            return "Nova Tvrtka";
        } else if (mode === "edit") {
            return "Uređivanje tvrtke";
        } else {
            return "Pregled detalja tvrtke";
        }
    };

    return (
        <div className={"company-page"} style={pageStyle}>
            <h3>Administracija tvrtki</h3>
            <CompanyAdministrationFilter
                form={filterForm}
                onFormFilterSubmit={onFormFilterSubmit}
            ></CompanyAdministrationFilter>
            <Flex vertical={false} justify="right" className="add-company-wrapper">
                <Button
                    type="dashed"
                    icon={<PlusOutlined/>}
                    style={{marginTop: "32px"}}
                    onClick={async () => {
                        companyDataForm.resetFields();
                        setMode("insert");
                        setCompanyAdministrationModalOpened(true);
                    }}
                >
                    Nova Tvrtka
                </Button>
            </Flex>
            <CompanyAdministrationTable
                tableData={tableData}
                onFilterChanged={(pagination, filters, sorter) =>
                    onFilterChanged(pagination, filters, sorter)
                }
                setMode={setMode}
                setCompanyAdministrationModalOpened={
                    setCompanyAdministrationModalOpened
                }
                setRowData={setRowData}
                style={{marginTop: "32px"}}
                pageNumber={pageNumber}
                pageSize={pageSize}
                totalRows={totalRows}
                bordered
                refreshData={() => fetchCompanies({searchTerm: ""})}
                fetchCompanyLogo={(companyId) => fetchCompanyLogo(companyId)}
            />
            <ModalComponent
                title={companyDataFormTitle()}
                show={companyAdministrationModalOpened}
                onOk={handleSubmitCompany}
                onCancel={() => {
                    setFileList([]);
                    setUploadedImage(null);
                    setPreviewImage(null)
                    setCompanyAdministrationModalOpened(false);
                }}
                loading={loading}
                showFooter={mode !== "preview"}
                width={"580px"}
            >
                <CompanyAdministrationForm
                    form={companyDataForm}
                    mode={mode}
                    onFinish={onCompanyDataSubmit}
                    initialValues={mode === "insert" ? initialValues : rowData}
                    owners={owners}
                    disabled={mode === "preview"}
                    uploadedImage={uploadedImage}
                    setUploadedImage={setUploadedImage}
                    fileList={fileList}
                    setFileList={setFileList}
                    previewImage={previewImage}
                    setPreviewImage={setPreviewImage}
                    previewOpen={previewOpen}
                    setPreviewOpen={setPreviewOpen}
                    activeKey={activeKey}
                    setActiveKey={setActiveKey}
                ></CompanyAdministrationForm>
            </ModalComponent>
        </div>
    );
};

export default CompanyAdministrationPage;
