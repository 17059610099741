import React, {useState, useEffect, useCallback} from "react";
import {momentLocalizer} from "react-big-calendar";
import moment from "moment";
import "moment/locale/hr";

import {axiosInstance} from "../../api/axiosInstance";

import "./TimeTrackingPage.css";
import CustomCalendar from "../../components/TimeTracking/CustomCalendar";
import {toast} from "react-toastify";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import TimeTrackingForm from "../../components/TimeTracking/TimeTrackingForm";
import dayjs from "dayjs";
import TimeTrackingItemForm from "../../components/TimeTracking/TimeTrackingItemForm";
import {Form, Select} from "antd";
import {setUiDisabled} from "../../slices/userDataSlice";
import {useDispatch} from "react-redux";

const localizer = momentLocalizer(moment);
moment.locale('hr');
var dayOfYear = require('dayjs/plugin/dayOfYear')
dayjs.extend(dayOfYear)

const TimeTrackingPage = ({userData}) => {

    const dispatch = useDispatch();

    const [workTypeId, setWorkTypeId] = useState(1);

    const [event, setEvent] = useState(null);
    const [events, setEvents] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');

    const [mode, setMode] = useState("insert");
    const [date, setDate] = useState(new Date())
    const onNavigate = useCallback((newDate) => setDate(newDate), [setDate])

    const [showModal, setShowModal] = useState(false);
    const [timeTrackingFormInitialValues, setTimeTrackingFormInitialValues] = useState({
        workTypeId: 1,
        timeRange:
            mode === "edit"
                ? dayjs(event.start).startOf('day')
                : dayjs(new Date()).startOf('day'),
        items: [],
    });
    const [timeTrackingItemFormInitialValues, setTimeTrackingItemFormInitialValues] = useState({
        projectId: mode === "edit" ? event.projectId : '',
        overtimeHours: mode === "edit" ? event.overtimeHours : 0,
        totalHours: mode === "edit" ? event.totalHours : 0,
        regularHours: mode === "edit" ? event.regularHours : 0,
        description: event?.description ? event.description : "",
        items: []
    });

    const [projects, setProjects] = useState([]);
    const [workTypes, setWorkTypes] = useState([]);
    const [timeTrackingItemFormErrors, setTimeTrackingItemFormErrors] = useState([]);
    const [eventActionLoading, setEventActionLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const [timeTrackingForm] = Form.useForm();
    const [timeTrackingItemForm] = Form.useForm();

    const mondayOfCurrentWeek = moment().weekday(0);

    const fetchUsers = async (values) => {
        return axiosInstance.get(`/api/v1/users/find-all`)
            .then((res) => {
                const mappedUsers = res.data
                    //.filter(x => x.username !== userData?.user)
                    .map((item) => ({
                        username: item.username,
                        label: `${item.firstName}  ${item.lastName}`,
                        value: item.id
                    }));
                setUsers(mappedUsers)
            });
    }

    const fetchByRange = async (props) => {
        return axiosInstance
            .post("/api/v1/time-tracking/find-all-by-user-id-and-time-period", {
                userId: props?.userId,
                dateFrom: props?.dateFrom,
                dateTo: props?.dateTo,
            })
            .then((res) => {
                const initialEvents = res.data.map((x) => {
                    return {
                        id: x.id,
                        title: x.project.name + " - " + x?.project?.investor?.name,
                        allDay: true,
                        start: moment(x.date).toDate(),
                        end: moment(x.date).toDate(),
                        projectId: x.project.id ? x.project.id : '',
                        workTypeId: x.workType?.id,
                        workType: x.workType?.name,
                        regularHours: x.regularHours,
                        overtimeHours: x.overtimeHours,
                        totalHours: x.totalHours,
                        overtime: x.overtimeHours,
                        description: x.description,
                    };
                });
                console.log(initialEvents, 'initialEvents')
                setEvents(initialEvents);
            });
    }

    const fetchUserProjects = () => {

        return axiosInstance.get("/api/v1/projects/find-all-by-user-and-role").then((res) => {
            const userProjects = res.data?.map((x) => {
                return {
                    projectId: x.id,
                    title: x.name,
                };
            });
            setProjects([{projectId: '', title: 'Odaberite posao'}].concat(userProjects));
        });
    }

    const fetchUserProjectsByUserId = (userId) => {

        return axiosInstance.get(`/api/v1/projects/find-all-by-user/${userId}`).then((res) => {
            const userProjects = res.data?.map((x) => {
                return {
                    projectId: x.id,
                    title: `${x.name} - ${x.investor.name}`,
                };
            });
            setProjects([{projectId: '', title: 'Odaberite posao'}].concat(userProjects));
        });
    }

    const fetchWorkTypes = () => {
        return axiosInstance.get("/api/v1/work-type").then((res) => {
            setWorkTypes(res.data);
        });
    }

    const checkUiDisabled = async () => {
        return axiosInstance.get("/api/v1/users/check-ui-disabled").then(res => {
            dispatch(setUiDisabled({uiDisabled: res.data}));
        });
    }

    const saveEvent = (event, startOfMonth, endOfMonth) => {
        return axiosInstance
            .post("/api/v1/time-tracking/event", {...event, userId: selectedUser})
            .then((res) => {
                setEvent({...event, id: res.data.id});
            }).catch(ex => {
                toast.error(ex.response.data.errorMessage);
            })
            .finally((onFinally) => {
                fetchByRange({userId: selectedUser, dateFrom: startOfMonth, dateTo: endOfMonth});
                checkUiDisabled();
                setEventActionLoading(false);
                setShowModal(false);
            });
    }

    const editEvent = (event, startOfMonth, endOfMonth) => {
        return axiosInstance
            .patch("/api/v1/time-tracking/event", {...event, id: event.id, userId: selectedUser})
            .then((res) => {
                setEvents((prev) => {
                    const existing = prev.find((ev) => ev.id === event.id);
                    const index = prev.findIndex((ev) => {
                        return ev.id === event.id;
                    });
                    let prevCopy = [...prev];
                    prevCopy.splice(index, 1);
                    prevCopy.push({
                        ...existing,
                        start: newEvent.start,
                        end: newEvent.end,
                        regularHours: newEvent.regularHours,
                        overtimeHours: newEvent.overtimeHours,
                        description: newEvent.description
                    });
                    return prevCopy;
                });
            }).catch(ex => {
                toast.error(ex.response.data.errorMessage)
            })
            .finally((onFinally) => {
                fetchByRange({userId: selectedUser, dateFrom: startOfMonth, dateTo: endOfMonth})
                checkUiDisabled();
                setEventActionLoading(false);
                setShowModal(false);
            });
    }

    const deleteEvent = async (eventToDelete) => {
        return await axiosInstance
            .delete(`/api/v1/time-tracking/event/${eventToDelete.id}`)
            .then((res) => {
                setEvents((prevEvents) =>
                    prevEvents.filter((event) => event.id !== eventToDelete.id)
                );
            }).finally(onFinally => {
                setEventActionLoading(false);
                checkUiDisabled();
            });
    }

    const fetchTimeTable = async (selectedUser, selectedMonth) => {

        console.log(selectedUser, 'selectedUser')

        return await axiosInstance
            .get(`/api/v1/time-tracking/time-table?userId=${selectedUser ? selectedUser : ''}&month=${selectedMonth ? selectedMonth : dayjs().month()}`, {responseType: 'blob'})
            .then(response => {

                const disposition = response.headers['content-disposition'];
                let filename = 'default_filename.xlsx'; // default value in case filename is not found

                if (disposition && disposition.includes('attachment')) {
                    const filenameRegex = /filename[^;=\n]*=(['"]?)([^'"\n]*)\1/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[2]) {
                        filename = matches[2];
                    }
                }

                const href = URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            });
    }


    useEffect(() => {

        setSelectedUser(userData?.userId)

        const fetchUserProjectsPromise = fetchUserProjectsByUserId(userData?.userId);
        const fetchWorkTypesPromise = fetchWorkTypes();

        Promise.all([fetchWorkTypesPromise, fetchUserProjectsPromise]);

        fetchUsers().then(async (res) => {
            await fetchByRange({
                userId: null,
                dateFrom: mondayOfCurrentWeek.toDate(),
                dateTo: mondayOfCurrentWeek.add(6, "day").toDate()
            });
        })


    }, []);

    useEffect(() => {
        if (mode === 'edit') {
            setTimeTrackingFormInitialValues({...event, workTypeId: event.workTypeId, timeRange: dayjs(event.start)});
            setTimeTrackingItemFormInitialValues({
                ...timeTrackingItemFormInitialValues,
                items: [{...event, regularHours: event.regularHours, overtimeHours: event.overtimeHours}]
            })
        }
    }, [event]);

    useEffect(() => {
        if (mode === 'edit') {
            timeTrackingForm.resetFields();
            timeTrackingItemForm.resetFields();
        }
    }, [timeTrackingFormInitialValues, timeTrackingItemFormInitialValues]);


    const handleSubmit = async () => {
        let validationResultGeneralForm;
        let validationResultItemForm;

        let tGeneralFormHasErrors = false;
        let tItemFormHasErrors = false;

        try {
            validationResultGeneralForm = await timeTrackingForm.validateFields();
            if (!validationResultGeneralForm.errorFields) {
                timeTrackingForm.submit();
                //setLoading(true);
            }
        } catch (ex) {
            console.log(ex);
            tGeneralFormHasErrors = true;
        }

        try {
            validationResultItemForm = await timeTrackingItemForm.validateFields();
            if (!validationResultItemForm.errorFields) {
                timeTrackingItemForm.submit();
                //setLoading(true);
            }
        } catch (ex) {
            console.log(ex);
            tItemFormHasErrors = true;
            setTimeTrackingItemFormErrors(ex.errorFields)
        }

        const itemFormValues = timeTrackingItemForm.getFieldsValue()?.items;

        if (itemFormValues && itemFormValues.length > 0) {
            const initialValue = 0;

            const sum = itemFormValues.map(x => x.regularHours).reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                initialValue,
            );

            console.log(events, 'events');


            const filteredEvents = events
                .filter(x => dayjs(x.start).dayOfYear() === dayjs(timeTrackingForm.getFieldValue("timeRange")).dayOfYear());
            console.log(filteredEvents, 'filteredEvents');

            let projectAlreadyExists = false;

            filteredEvents.forEach(event => {
                if (itemFormValues.some(x => x.projectId === event.projectId)) {
                    projectAlreadyExists = true;
                }
            })

            if (mode !== 'edit' && projectAlreadyExists) {
                toast.error("Moguće je unijeti samo jednu vrstu posla u danu.");
                return;
            }

            // current sum for date

            const eventsSum = filteredEvents.map(x => x.regularHours).reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                initialValue,
            );

            console.log(eventsSum, 'eventsSum')

            if (mode === 'insert' && (sum > 8 || eventsSum + sum > 8)) {
                toast.error("Moguće je upisati maksimalno 8 radnih sati.");
                return;
            }
        }

        if (tGeneralFormHasErrors || tItemFormHasErrors || itemFormValues?.length === 0) {
            if (itemFormValues?.length === 0) {
                toast.error("Forma nema niti jednu stavku.");
            }
        } else {
            onSubmit({
                generalData: timeTrackingForm.getFieldsValue(),
                itemData: timeTrackingItemForm.getFieldsValue()
            });
        }
    };

    const onSubmit = (formData) => {

        const startOfMonth = moment(formData.generalData.timeRange.toDate()).startOf('month').subtract(1, 'months').toDate();
        const endOfMonth = moment(formData.generalData.timeRange.toDate()).endOf('month').add(1, 'months').toDate();

        // if workType is regular work
        if (formData.generalData.workTypeId === 1) {
            formData.itemData.items.forEach(item => {

                const project = projects.filter((x) => x.projectId == item.projectId)[0];
                const newEvent = {
                    title: project.title,
                    start: dayjs(formData.generalData.timeRange).format("YYYY-MM-DD"),
                    end: dayjs(formData.generalData.timeRange).format("YYYY-MM-DD"),
                    workTypeId: formData.generalData.workTypeId,
                    projectId: item?.projectId,
                    regularHours: item?.regularHours,
                    overtimeHours: item?.overtimeHours,
                    description: item?.description
                };

                if (mode === "insert") {
                    setEventActionLoading(true);
                    saveEvent(newEvent, startOfMonth, endOfMonth);
                } else if (mode === "edit") {
                    setEventActionLoading(true);
                    editEvent({...newEvent, id: event.id}, startOfMonth, endOfMonth)
                }
            })
        } else {
            const newEvent = {
                title: workTypes.filter((x) => x.id == formData.generalData.workTypeId)[0].name,
                start: dayjs(formData.generalData.timeRange).format("YYYY-MM-DD"),
                end: dayjs(formData.generalData.timeRange).format("YYYY-MM-DD"),
                workTypeId: formData.generalData.workTypeId,
                projectId: null,
                regularHours: 8,
                overtimeHours: 0,
                description: ''
            };

            if (mode === "insert") {
                setEventActionLoading(true);
                saveEvent(newEvent, startOfMonth, endOfMonth);
            } else if (mode === "edit") {
                setEventActionLoading(true);
                editEvent({...newEvent, id: event.id}, startOfMonth, endOfMonth)
            }
        }
    }

    const newEvent = useCallback(
        (event) => {
            setEvents((prev) => {
                return [...prev, {
                    ...event,
                    start: moment(event.start).toDate(),
                    end: moment(event.end).toDate(),
                    allDay: true
                }];
            });
        },
        [setEvents]
    );

    const currentUserObject = selectedUser ? users.filter(x => x.value === selectedUser)[0] : users.filter(x => x.username === userData?.user)[0];

    return (
        <div className={"time-tracking-page"}>
            <h3>Evidencija radnog vremena za: {currentUserObject?.label}</h3>
            {userData?.authorities?.includes('ROLE_ADMINISTRATOR') ?
                <>
                    <p>Prikaz evidencije po korisniku:</p>
                    <Select
                        placeholder={"Korisnik"}
                        options={users}
                        showSearch
                        value={selectedUser}
                        onClear={() => setSelectedUser(null)}
                        onChange={(selectedUser) => {
                            setSelectedUser(selectedUser);
                            console.log(date, 'date');
                            fetchUserProjectsByUserId(selectedUser);
                            fetchByRange({
                                userId: selectedUser,
                                dateFrom: dayjs(date).subtract(1, "month").toDate(),
                                dateTo: dayjs(date).add(1, "month").toDate()
                            })
                        }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                        style={{width: '200px', marginTop: ''}}
                    ></Select>
                </> : <></>
            }
            <CustomCalendar
                date={date}
                setDate={setDate}
                onNavigate={onNavigate}
                selectedUser={selectedUser}
                timeTrackingForm={timeTrackingForm}
                timeTrackingItemForm={timeTrackingItemForm}
                userData={userData}
                localizer={localizer}
                workTypes={workTypes}
                setWorkTypeId={setWorkTypeId}
                projects={projects}
                timeTrackingItemFormErrors={timeTrackingItemFormErrors}
                setTimeTrackingItemFormErrors={setTimeTrackingItemFormErrors}
                events={events}
                setEvents={setEvents}
                setEvent={setEvent}
                deleteEvent={(eventToDelete) => deleteEvent(eventToDelete)}
                eventActionLoading={eventActionLoading}
                setEventActionLoading={setEventActionLoading}
                setMode={setMode}
                setShowModal={setShowModal}
                fetchByRange={(userId, dateFrom, dateTo) => fetchByRange(userId, dateFrom, dateTo)}
                fetchTimeTable={(selectedUser, selectedMonth) => fetchTimeTable(selectedUser, selectedMonth)}
            ></CustomCalendar>
            <ModalComponent
                title={"Evidencija radnog vremena"}
                show={showModal}
                onOk={(values) => {
                    handleSubmit();

                }}
                onCancel={() => {
                    timeTrackingForm.resetFields();
                    timeTrackingItemForm.resetFields();
                    setShowModal(false);
                }}
                loading={loading}
                showFooter={true}
                width={"580px"}
            >
                <TimeTrackingForm
                    event={event}
                    initialValues={timeTrackingFormInitialValues}
                    workTypes={workTypes}
                    setWorkTypeId={setWorkTypeId}
                    mode={mode}
                    setEvent={newEvent}
                    form={timeTrackingForm}
                    timeTrackingItemForm={timeTrackingItemForm}
                    projects={projects}
                    setShowModal={setShowModal}
                    setEventActionLoading={setEventActionLoading}
                ></TimeTrackingForm>
                <TimeTrackingItemForm
                    form={timeTrackingItemForm}
                    initialValues={timeTrackingItemFormInitialValues}
                    mode={mode}
                    projects={projects}
                    timeTrackingItemFormErrors={timeTrackingItemFormErrors}
                    setTimeTrackingItemFormErrors={setTimeTrackingItemFormErrors}
                    workTypeId={workTypeId}
                    setShowModal={setShowModal}
                ></TimeTrackingItemForm>
            </ModalComponent>
        </div>
    );
};

export default TimeTrackingPage;
